import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faThumbsDown } from '@fortawesome/pro-solid-svg-icons/faThumbsDown'
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp'
import {
  Badge,
  EndorsementList,
  Markdown,
  Notification,
} from 'components/sparkles'
import extractBlob from 'utils/extractBlob'
import './MeasureCard.scss'
import measureCardContainer from './MeasureCardContainer'

class MeasureCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  toggleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { measure, showCivicEngineMeasureInfo, togglable, t } = this.props
    const { open } = this.state

    // Only show thums up/down when there's a single endorsement
    const endorsement = measure.tenant_endorsement

    const endorsementsNo = (measure.endorsements || []).filter(
      e => e.yes_no === 'no',
    )
    const endorsementsYes = (measure.endorsements || []).filter(
      e => e.yes_no === 'yes',
    )

    const blob = extractBlob(measure.markdowns, 'default')

    return (
      <section className="MeasureCard">
        <header className="MeasureCard__header">
          <div>
            {measure.has_unknown_boundaries && (
              <Notification
                className="PositionPanel__Header__Notification"
                variant="warning"
              >
                {t('voter_guide:MeasurePanel.Header:measureBoundariesAlert')}
              </Notification>
            )}
            {measure.name && <h3>{measure.name}</h3>}
            {measure.title && <p>{measure.title}</p>}
            {endorsement && endorsement.yes_no && (
              <h2>
                <Badge
                  variant={endorsement.yes_no === 'yes' ? 'success' : 'danger'}
                >
                  Vote
                  <span className="MeasureCard__endorsed-choice">
                    {endorsement.yes_no}
                  </span>
                  <FontAwesomeIcon
                    icon={
                      endorsement.yes_no === 'yes' ? faThumbsUp : faThumbsDown
                    }
                  />
                </Badge>
              </h2>
            )}
          </div>
          {togglable && (
            <button
              aria-label="Toggle"
              className="MeasureCard__toggle"
              onClick={this.toggleOpen.bind(this)}
            >
              <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
            </button>
          )}
        </header>

        {togglable && (
          <div
            className={classNames('MeasureCard__details', {
              'MeasureCard__details--open': open,
            })}
          >
            {endorsement && endorsement.comment && (
              <Markdown source={endorsement.comment} />
            )}

            {showCivicEngineMeasureInfo && measure.text && (
              <div>
                <h4>Measure Text</h4>
                <Markdown source={measure.text} />
              </div>
            )}

            {endorsementsYes && endorsementsYes.length > 0 && (
              <div>
                <h4>Endorsements - YES</h4>
                <EndorsementList endorsements={endorsementsYes} />
              </div>
            )}

            {endorsementsNo && endorsementsNo.length > 0 && (
              <div>
                <h4>Endorsements - NO</h4>
                <EndorsementList endorsements={endorsementsNo} />
              </div>
            )}

            {blob && <Markdown source={blob} />}
          </div>
        )}
      </section>
    )
  }
}

MeasureCard.defaultProps = {
  togglable: true,
}

MeasureCard.propTypes = {
  measure: PropTypes.object.isRequired,
  togglable: PropTypes.bool,
}

export default measureCardContainer(withTranslation()(MeasureCard))
